//comm js
function check_login(){
  let query = window.location.href.split('#');
    if(query.length>1){
        let tem = query[query.length-1].split('&');
        if(tem[0].length>1){
            tem.map(ee=>{
                if (ee) {
                let a = ee.split('=');
                localStorage.setItem(a[0],a[1])
                
                }
            })
        }
    }
    if (localStorage.getItem('id_token')) {
        
        var tokens=localStorage.getItem('id_token');
        setCookie('id_token',tokens,3); 
        console.log(tokens);
        var decoded = decodeURIComponent(escape(window.atob(tokens.split('.')[1])));
        decoded=JSON.parse(decoded);
        let name = decoded['email'].split('@')[0]
        let timestamp=(new Date().getTime())/1000;
        if(localStorage.getItem('cur_lan')=='' || localStorage.getItem('cur_lan')==null){
            localStorage.setItem('cur_lan','jp');
        }
        if(timestamp<decoded['exp']+252000){   
            setCookie('id_token',tokens,3);       
            if (decoded && decoded['email']!=localStorage.getItem('email')) {
                setCookie('email',decoded['email'],3); 
                localStorage.setItem('name', name);
                localStorage.setItem('email_verified', decoded['email_verified']);
                localStorage.setItem('email', decoded['email']);
                if (decoded['cognito:groups']) {
                localStorage.setItem('groups', decoded['cognito:groups'].join(' '));
                }else{
                    localStorage.setItem('groups', 'user');	
                }
            }
            
            
            
        }else{
            localStorage.setItem('id_token',''); 
        }
    
    }
    console.log("aaa"+localStorage.getItem('id_token') );
    if (!localStorage.getItem('id_token') ) {

        window.location.href = 'https://cbredata.com/index.php?to_type=cbreai';//'https://auth.cbredata.com/login?client_id=1gcmfopbf7qtttffm228f52657&response_type=token&redirect_uri=https://cbredata.com/jp/index.php';                          
    }
}

function setCookie(name, value, daysToLive) {
    // 对 cookie 值进行编码以转义其中的分号、逗号和空格
    var cookie = name + "=" + encodeURIComponent(value);
   
    if(typeof daysToLive === "number") {
        /* 设置 max-age 属性 */
        cookie += "; max-age=" + (daysToLive*24*60*60);
    }
    document.cookie = cookie;
}
export {
  check_login
}